import React from "react"

import Layout from "../components/global/layout"
import SEO from "../components/global/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className="container-left container-right privacy">
      <h1 style={{ marginTop: "30px" }}>sorry.</h1>
      <p style={{ marginTop: "20px" }}>
        looks like something went wrong our end. head back to the{" "}
        <a href="/" style={{ textAlign: "underline" }}>
          Blistex homepage.
        </a>
      </p>
    </section>
  </Layout>
)

export default NotFoundPage
